/**
 * @file
 * Alignment classes for text and block level elements.
 */

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}
.text-align-justify {
  text-align: justify;
}

/**
 * Alignment classes for block level elements (images, videos, blockquotes, etc.)
 */
.align-left {
  float: left;
  padding-right: 10px;
}
.align-right {
  float: right;
  padding-left: 10px;
}
.align-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a.button_link {
  color: rgb(232, 75, 49);
  cursor: pointer;
  display: inline-flex;
  outline: none;
  padding: 10px 10px 10px 10px;
  font-size: 14px;
  min-width: 170px;
  background: none;
  min-height: 36px;
  align-items: center;
  border-color: rgb(232, 75, 49);
  border-style: solid;
  border-width: 1.5px;
  border-radius: 9999px;
  text-transform: uppercase;
  justify-content: space-around;
  text-decoration: none;
  appearance: none;
}
